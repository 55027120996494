import React, { useEffect, useRef, useState } from 'react'

import 'twin.macro'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { parse } from 'query-string'
import { SubmitHandler, useForm } from 'react-hook-form'

import withLocation from 'components/shared/with-location'
import { useProspectSendDashboardLink } from 'context/prospect/use-send-dashboard-link'
import useProspectUniqueLazy from 'context/prospect/use-unique-lazy'
import useUserUniqueLazy from 'context/user/use-unique-lazy'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { emailIsValid } from 'views/utils'

const Login = ({ location }: RouteComponentProps) => {
  const { fetchUser } = useUserUniqueLazy()
  const { fetchProspect } = useProspectUniqueLazy()
  const { previousSendSuccessful, sendDashboardLink } =
    useProspectSendDashboardLink()

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    getValues,
    setError,
    setValue,
  } = useForm<{ email: string }>({
    defaultValues: { email: '' },
    mode: 'onBlur',
  })
  const [showProspectConfirmation, setShowProspectConfirmation] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [disableResend, setDisableResend] = useState(true)
  const timeout = useRef<NodeJS.Timeout>()

  const handleSendEmail = async () => {
    setDisableResend(true)

    await sendDashboardLink(getValues('email'))

    timeout.current = setTimeout(() => {
      setDisableResend(false)
    }, 60000)
  }

  const onSubmit: SubmitHandler<{ email: string }> = async ({ email }) => {
    setLoading(true)

    const emailLowerCase = email?.toLowerCase()

    const { user } = await fetchUser({
      variables: { where: { email: emailLowerCase } },
    })

    if (user) {
      navigate(`/login-user?email=${encodeURIComponent(emailLowerCase)}`)
      return
    }

    const { prospect } = await fetchProspect({
      variables: { where: { email: emailLowerCase } },
    })

    if (prospect) {
      await handleSendEmail()
      setLoading(false)
      setShowProspectConfirmation(true)
      return
    }
    setError('email', {
      message:
        "We couldn't find that email! Please check for typos or start a new project with us",
      type: 'notFound',
    })
    setLoading(false)
  }

  useEffect(() => {
    document.body.style.margin = '0'
    if (location?.search) {
      const { email } = parse(location.search) as { email?: string }
      if (emailIsValid(email)) {
        setValue('email', email as string)
        handleSubmit(onSubmit)()
      }
    }

    return () => {
      document.body.style.margin = ''
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  return (
    <div tw="grid grid-cols-1 md:grid-cols-2 h-screen">
      <div tw="flex flex-col items-center justify-center p-8 text-center md:p-15">
        <h1 tw="text-4xl font-thin font-sans mb-6">
          {showProspectConfirmation ? 'All set!' : 'Welcome back'}
        </h1>
        <h6 tw="text-gray-400 max-w-sm font-sans text-lg font-light !m-0 leading-7">
          {showProspectConfirmation
            ? 'We have sent you an email to get access to your dashboard, please verify your inbox'
            : 'Enter your email to continue'}
        </h6>
        <form
          onSubmit={handleSubmit(onSubmit)}
          tw="w-full max-w-sm flex flex-col mt-8"
        >
          <input
            {...register('email', {
              required: true,
              validate: (value) =>
                emailIsValid(value) || 'Please provide a valid email',
            })}
            type="email"
            placeholder="you@example.com"
            tw="font-sans bg-white text-base block h-6 px-3 py-2 border border-solid border-gray-300 rounded-sm placeholder:(text-base text-gray-400) focus:(ring-gray-100 border-gray-700)"
            style={{
              borderColor: errors?.email?.message ? '#C77357' : 'unset',
            }}
            readOnly={showProspectConfirmation}
          />
          {errors?.email?.message && (
            <p tw="text-left text-gray-700 m-0 mt-2 font-sans text-sm">
              {errors.email.type === 'notFound' ? (
                <>
                  {errors.email.message}{' '}
                  <a
                    href="/welcome/new/project-info/your-style"
                    tw="no-underline text-gray-900"
                  >
                    here
                  </a>
                  .
                </>
              ) : (
                errors.email.message
              )}
            </p>
          )}

          {showProspectConfirmation ? (
            <p tw="text-gray-400 font-sans text-base">
              Haven't received the email yet?
              <br />
              <Button
                rounded
                onClick={handleSendEmail}
                color="transparentBlack"
                tw="ml-1 mr-1 my-4"
                disabled={previousSendSuccessful && disableResend}
              >
                Send again
              </Button>
              <Button
                rounded
                color="transparentBlack"
                onClick={() => setShowProspectConfirmation(false)}
                tw="ml-1"
              >
                Try another email address
              </Button>
            </p>
          ) : (
            <Button
              rounded
              color="transparentBlack"
              loading={loading}
              disabled={!isValid}
              tw="mt-8"
            >
              Continue
            </Button>
          )}
        </form>
      </div>
      <img
        alt="FORM Kitchens"
        src={`${process.env.GATSBY_CDN_ROOT}/images/login/DEE_CHEN_V2_02.jpg`}
        tw="object-cover w-full h-full invisible hidden md:(visible block)"
      />
    </div>
  )
}

export default withLocation(Login)
